import React from 'react'
import Image from '../assets/img/404.svg'
import { LoadableQuintButton } from '../components/LoadableComponents'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const NotFoundPage = () => {
  return (
    <div style={{
      height: '100vh',
      width: '100vw',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
    }}>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div>
          <div className='quint-heading-big3 font-main color-primary' style={{ marginBottom: 16 }}>
              Oh, that's a hard hit.
          </div>
          <div className='quint-subtitle font-main color-primary-dark' style={{ marginBottom: 32 }}>
              You just scored a 404 error. Better luck next time.
          </div>
          <AniLink
            to='/'
            style={{ textDecoration: 'none' }}>
            <LoadableQuintButton
              boxShadow='true' rounded='contained' border={false} textColor='#ffffff' background='#25317B'
              className='text-align-center-on-small'>
                Back to the site
            </LoadableQuintButton>
          </AniLink>
        </div>
      </div>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={Image} alt='quint-404-image' style={{ height: '80%', width: '80%' }} />
      </div>
    </div>
  )
}

export default NotFoundPage

